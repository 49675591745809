<template>
  <v-container fluid class="down-top-padding" loading>
    <Carousel
      v-if="carousel_dialog"
      :photoList="photoList"
      :now="now"
      @closed-dialog="carousel_dialog = $event"
    ></Carousel>
    <v-snackbar v-model="snackbar" timeout="3000" top>
      {{ msg }}
    </v-snackbar>
    <v-sheet class="text-h6 pa-3 left-color rounded">
      {{ title }}
      <EditTitle
        :authority="authority.photo"
        :editItem="title"
        @my-click="editAlbumTitle($event)"
      ></EditTitle>
    </v-sheet>
    <v-row>
      <v-col cols="6">
        <v-file-input
          v-if="authority.photo"
          chips
          multiple
          label="画像のアップロード"
          accept=".png,.jpg,.jpeg"
          @change="uploadFiles"
          v-model="files"
        ></v-file-input>
      </v-col>
    </v-row>
    <v-row class="ma-3">
      <v-col
        v-for="(item, index) in photoList"
        :key="item.url"
        class="d-flex child-flex"
        cols="12"
        md="4"
      >
        <v-img
          @click="prv_img(index)"
          :src="item.url"
          :lazy-src="item.url"
          class="grey lighten-2"
        >
          <DeletePhoto
            v-if="authority.photo"
            @my-click="deletePhoto(item.filename)"
          >
          </DeletePhoto>
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import _ from "lodash";
import DeletePhoto from "@/components/photo/DeletePhoto";
import Carousel from "@/components/photo/Carousel";
import EditTitle from "@/components/photo/EditTitle";
export default {
  name: "PhotoPageDetail",

  data: () => ({
    dialog: false,
    files: [],
    msg: "",
    title: "",
    photoList: [],
    authority: [],
    error: false,
    snackbar: false,
    carousel_dialog: false,
    now: 0,
  }),
  components: { DeletePhoto, Carousel, EditTitle },
  methods: {
    uploadFiles(event) {
      if (!event.length == 0) {
        this.checkImgFormat(event);
        if (!this.error) {
          const files = new FormData();
          event.forEach((elem) => {
            files.append("files", elem);
          });
          axios
            .post(
              process.env.VUE_APP_API_URL +
                `/regist-photo?id=${this.$route.query.id}`,
              files,
              {
                headers: { "content-type": "multipart/form-data" },
              }
            )
            .then(() => {
              this.msg = "画像をアップロードしました";
              this.snackbar = true;
              this.getPhotoList();
            });
        }
      }
    },
    checkImgFormat(event) {
      event.forEach((element) => {
        if (
          !(
            element.type == "image/png" ||
            element.type == "image/jpg" ||
            element.type == "image/jpeg"
          )
        ) {
          this.msg = "png,jpg,jpeg形式の画像ファイルを選択してください";
          this.snackbar = true;
          this.error = true;
        }
      });
    },
    getPhotoList() {
      this.files.splice(0);
      axios
        .get(process.env.VUE_APP_API_URL + "/get-photo-list", {
          params: { id: this.$route.query.id },
        })
        .then((response) => {
          this.title = response.data.title;
          this.authority = response.data.authority;
          this.photoList = _.sortBy(response.data.imgUrlList);
        });
    },
    deletePhoto(filename) {
      axios
        .post(process.env.VUE_APP_API_URL + "/delete-photo", {
          filename: this.$route.query.id + "/" + filename,
        })
        .then(() => {
          this.msg = "削除しました";
          this.snackbar = true;
          this.getPhotoList();
        });
    },
    prv_img(index) {
      this.carousel_dialog = true;
      this.now = index;
    },
    editAlbumTitle(e) {
      axios
        .post(process.env.VUE_APP_API_URL + "/update-photo-title", {
          id: this.$route.query.id,
          title: e,
        })
        .then(() => {
          this.msg = "アルバム名を変更しました";
          this.snackbar = true;
          this.getPhotoList();
        });
    },
  },
  mounted() {
    this.getPhotoList();
  },
};
</script>
<style>
.v-image {
  cursor: pointer;
  cursor: hand;
}
</style>