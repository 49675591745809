<template>
  <v-dialog v-model="dialog" persistent max-width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        v-bind="attrs"
        v-on="on"
        @click="title = editItem"
        v-show="authority"
      >
        mdi-pencil
      </v-icon>
    </template>
    <v-card>
      <v-form v-model="valid" ref="form">
        <v-sheet class="text-h6 left-color pa-2">アルバム名変更</v-sheet>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="title"
                :rules="rules"
                @keydown.enter.prevent="send()"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="clear()"> キャンセル </v-btn>
        <v-btn color="primary" text @click="send()"> 登録 </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ["editItem", "authority"],
  data() {
    return {
      dialog: false,
      valid: false,
      rules: [(v) => !!v || "入力してください"],
      title: "",
    };
  },
  methods: {
    clear() {
      this.title = this.editItem;
      this.dialog = false;
    },
    send() {
      if (this.$refs.form.validate()) {
        this.dialog = false;
        this.$emit("my-click", this.title);
        this.clear();
      }
    },
  },
};
</script>