<template>
  <div class="text-center">
    <v-dialog v-model="dialog" max-width="1000">
      <v-carousel v-model="model" hide-delimiters height="auto">
        <v-carousel-item
          v-for="item in photoList"
          :key="item.url"
          :src="item.url"
        >
          <v-row class="ma-3" align="top" justify="end">
            <div>{{ now + 1 + "/" + photoList.length }}</div>
          </v-row>
        </v-carousel-item>
      </v-carousel>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "Carousel",
  data: () => ({ dialog: true }),
  props: ["photoList", "now"],
  computed: {
    model: {
      get: function () {
        return this.now;
      },
      set: function (newValue) {
        this.now = newValue;
        this.$emit("now", this.now);
      },
    },
  },
  mounted() {},
  watch: {
    dialog() {
      this.$emit("closed-dialog", false);
    },
  },
};
</script>
<style>
</style>